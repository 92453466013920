function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

function showErrorPopUp(msg) {
  const pop_up_div = document.getElementById('onboarding-error');
  const errorMsgEl = document.getElementById('hvErrorMsg');
  const closeButtonEl = document.getElementById('popup-close-button');
  errorMsgEl.innerHTML =
    msg || 'There was an error processing your request. Please try again.';
  pop_up_div.style.display = 'block';

  closeButtonEl.addEventListener('click', function (event) {
    event.preventDefault();
    pop_up_div.style.display = 'none';
  });
}

function closePopup() {
  const pop_up_div = document.getElementById('onboarding-error');
  pop_up_div.style.display = 'none';
}

function render_success_page() {
  window.location.href = '/onboarding/status';
}

async function trigger_sentry_error(error_message, error_stack) {
  const csrftoken = getCookie('csrftoken');
  await fetch(`${window.location.origin}/capture-error/`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
    body: JSON.stringify({
      error: error_message,
      error_stack: error_stack,
    }),
  });
}

async function fetchUserDetails(jwtToken) {
  try {
    const url = `${window._Torus.BFF_BASE_URL}/users/me`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch user details');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      `There was an error while fetching user details`,
      error.stack,
    );
  }
}

async function fetchOnboarding(jwtToken) {
  try {
    const url = `${window._Torus.BFF_BASE_URL}/onboarding`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Failed to fetch onboarding details');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      `There was an error while fetching onboarding details`,
      error.stack,
    );
  }
}

async function fetchGenerateToken(jwtToken) {
  try {
    const url = `${window._Torus.BFF_BASE_URL}/onboarding/generate-token`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    if (!response.ok) {
      throw new Error('Failed to generate token');
    }
    return response.json();
  } catch (error) {
    trigger_sentry_error(
      'There was an error while generating token',
      error.stack,
    );
  }
}

async function startOnBoarding(jwtToken) {
  const overlayClassName = 'onboarding-redirect';
  try {
    document.getElementById(overlayClassName).style.display = 'block';

    const userDetailsResponse = await fetchUserDetails(jwtToken);
    const email = userDetailsResponse.data.user.email;
    const mobileNumber = userDetailsResponse.data.user.phone;

    const onboardingResponse = await fetchOnboarding(jwtToken);
    const transactionId = onboardingResponse.data.hypervergeTransactionID;
    const hypervergeUUID = onboardingResponse.data.hypervergeUUID;

    const generatedToken = await fetchGenerateToken(jwtToken);
    const accessToken = generatedToken.token;
    const workflowId = window._Torus.HV_WORKFLOW_ID;
    const appId = window._Torus.HV_APP_ID;
    try {
      window.HyperKYCModule.prefetch(appId, workflowId);
    } catch (error) {
      console.error('Error in prefetch:', error);
    }
    const hyperKycConfig = new window.HyperKycConfig(
      accessToken,
      workflowId,
      transactionId,
    );

    const customInputs = {
      leadId: transactionId,
      mobileNumber,
      email: email || '',
    };

    hyperKycConfig.setUniqueId(hypervergeUUID);
    hyperKycConfig.setInputs(customInputs);
    hyperKycConfig.setCustomFontStylesheet(window._Torus.HV_FONT_STYLESHEET);

    const handler = (HyperKycResult) => {
      const messages = {
        digilocker_verification_failed:
          'Oops! We encountered a DigiLocker system error. Please try again later',
        pan_verification_failed:
          'Oops! PAN Verification failed. Please try again later',
        db_ocr_name_match_failure:
          'Oops! Name mismatch detected. Please verify details and try again',
        pan_db_check_verification_failed:
          'Oops! PAN verification failed. Please verify the details and try again',
        pan_dedupe_verification_failed: 'Oops! PAN already exits',
        aml_verification_failed:
          'OOps! PAN is barred. Please contact support for assistance',
        bank_account_verification_failed:
          'Oops! Bank account verification failed. Please try again later',
        bank_name_pan_name_match_failed:
          'Oops! Bank Proof name mismatch detected. Please verify the details and try again',
        selfie_verification_failed:
          'Oops! Selfie verification failed. Please try again',
        facematch_verification_failed:
          'Oops! Facematch failed. Please try again',
        signature_verification_failed:
          'Oops! signature verification failed. Please try again',
        esign_verification_failed:
          'Oops! Esigner name mismatch detected. Please verify the details and try again',
        pan_ocr_name_mismatch: 'name mismatch between PAN OVD and Aadhaar',
        name_split_api_failed:
          'Name split API returned empty values or failed to execute',
        gov_nsdl_server_down:
          'NSDL servers are down for validating the PAN details',
      };

      document.getElementById(overlayClassName).style.display = 'none';
      switch (HyperKycResult.status) {
        case 'user_cancelled':
          showErrorPopUp('Your kyc is pending for completion');
          trigger_sentry_error(
            `Hyperverge: ${HyperKycResult.status}`,
            HyperKycResult,
          );
          break;
        case 'error':
          showErrorPopUp('Something went wrong...');
          trigger_sentry_error(
            `Hyperverge: ${HyperKycResult.status}`,
            HyperKycResult,
          );
          break;
        case 'auto_approved':
          render_success_page();
          break;
        case 'auto_declined':
          showErrorPopUp(
            messages?.[HyperKycResult.errorType] ||
              HyperKycResult.details.errorMessage,
          );
          trigger_sentry_error(
            `Hyperverge: ${HyperKycResult.status}`,
            HyperKycResult,
          );
          break;
        case 'needs_review':
          showErrorPopUp(
            messages?.[HyperKycResult.errorType] ||
              HyperKycResult.details.errorMessage,
          );
          trigger_sentry_error(
            `Hyperverge: ${HyperKycResult.status}`,
            HyperKycResult,
          );
          break;
        default:
          showErrorPopUp(messages[HyperKycResult.errorType]);
          trigger_sentry_error('Hyperverge : Unknown status', HyperKycResult);
      }
    };
    window.HyperKYCModule.launch(hyperKycConfig, handler);
  } catch (error) {
    document.getElementById(overlayClassName).style.display = 'none';
    showErrorPopUp('Something went wrong...');
    trigger_sentry_error('There was an error in startOnBoarding', error.stack);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const urlparams = new URLSearchParams(window.location.search);
  const ctx = urlparams.get('ctx');
  if (ctx === 'hv') {
    const head = document.getElementsByTagName('head')[0];
    const js = document.createElement('script');

    js.type = 'text/javascript';
    js.onerror = () => {
      console.log('HV load Failed');
    };
    js.onload = () => {
      console.log('HV loaded');
      const jwtToken = getCookie('llt');
      window.history.replaceState({}, document.title, window.location.pathname);
      startOnBoarding(jwtToken);
    };
    js.src =
      'https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.13.2/src/sdk.min.js';

    head.appendChild(js);
  }
});

document
  .getElementById('try_again_button')
  .addEventListener('click', function (event) {
    event.preventDefault();
    window.location.href = window.location.pathname + '?ctx=hv';
    closePopup();
  });
